$(document).ready(function() {
    //
    // Scroll To Top
    //

    (function() {
        var $document = $(document);
        var $scrollTotop = $(".scroll-to-top");
        if ($scrollTotop.length > 0) {
            if ($document.scrollTop() > 300) {
                $scrollTotop.show();
            }
            $document.on("scroll", function() {
                if ($document.scrollTop() > 300) {
                    $scrollTotop.show();
                } else {
                    $scrollTotop.fadeOut();
                }
            });
            $scrollTotop.click(function(e) {
                e.preventDefault();
                $("html, body").animate({
                        scrollTop: 0,
                    },
                    500
                );
                return false;
            });
        }
    })();
});

function log(e) {
    console.log(JSON.parse(JSON.stringify(e)));
}

// 根据天数计算日期
function computeDateByDay(num = 0, separator = '-') {
    let date = new Date(); //没有传入值时,默认是当前日期
    date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    date += " 00:00:00"; //设置为当天凌晨12点
    date = Date.parse(new Date(date)) / 1000; //转换为时间戳
    date += (86400) * Number(num); //修改后的时间戳
    var newDate = new Date(parseInt(date) * 1000); //转换为时间
    return newDate.getFullYear() + separator + (newDate.getMonth() + 1) + separator + newDate.getDate();
}

// 获取当前月截止日期
function computeDateByMonth(num = 0, separator = '-') {
    let date = new Date(); //没有传入值时,默认是当前日期
    let computeDate = new Date(date.getFullYear(), (date.getMonth() + Number(num)), 0);
    let endMonth = (computeDate.getMonth() + 1);
    endMonth = endMonth < 10 ? '0'+endMonth : endMonth;
    let endDate = computeDate.getDate();
    endDate = endDate < 10 ? '0'+endDate : endDate;
    return computeDate.getFullYear() + separator + endMonth + separator + endDate;
}

// 日期格式去除秒
function unsetSecond(dateTime){
    return dateTime.substring(0,16);
}

function isNotNull(v){
    if(v!==null && v!==''){
        return true
    }else{
        return false
    }
}

var storage = {
    set(key,value){
        sessionStorage.setItem(key,JSON.stringify(value));
    },
    get(key){
        return JSON.parse(sessionStorage.getItem(key));
    },
    remove(key){
        sessionStorage.removeItem(key);
    },
    clear(){
        sessionStorage.clear();
    },
}

let throttleStop = false;
 
//节流
function throttle(callback){
	if(throttleStop){
		return;
	}
	throttleStop = true;
	callback(()=>{
		throttleStop = false;
	})
}

/**
 * localStorage
 * @调用：_local.set('access_token', '0987654321', 5000);
 * @调用：_local.get('access_token');
 */
var _local = {
    //存储,可设置过期时间
    set(key, value, expires) {
        let params = { key, value, expires };
        if (expires) {
            // 记录何时将值存入缓存，毫秒级
            var data = Object.assign(params, { startTime: new Date().getTime() });
            localStorage.setItem(key, JSON.stringify(data));
        } else {
            if (Object.prototype.toString.call(value) == '[object Object]') {
                value = JSON.stringify(value);
            }
            if (Object.prototype.toString.call(value) == '[object Array]') {
                value = JSON.stringify(value);
            }
            localStorage.setItem(key, value);
        }
    },
    //取出
    get(key) {
        let item = localStorage.getItem(key);
        // 先将拿到的试着进行json转为对象的形式
        try {
            item = JSON.parse(item);
        } catch (error) {
            // eslint-disable-next-line no-self-assign
            item = item;
        }
        // 如果有startTime的值，说明设置了失效时间
        if (item && item.startTime) {
            let date = new Date().getTime();
            // 如果大于就是过期了，如果小于或等于就还没过期
            if (date - item.startTime > item.expires) {
                localStorage.removeItem(name);
                return false;
            } else {
                return item.value;
            }
        } else {
            return item;
        }
    },
    // 删除
    remove(key) {
        localStorage.removeItem(key);
    },
    // 清除全部
    clear() {
        localStorage.clear();
    }
}